<template>
  <div>
    <Modal
      v-model="showModal"
      title="Générer un attachement"
      @close="modalClosed"
    >
      <form>
        <b-alert show variant="info">
          <i class="mdi mdi-alert-circle-outline mr-2"></i>Le système prend en considération que les opérations validés. <br>
          <i class="mdi mdi-alert-circle-outline mr-2"></i>Le contrat utilisé: <b>{{ contract.ref }}</b>.
        </b-alert>

        <div class="row">
          <div class="col-md-5">
            <label for="formrow-inputCity">Période *</label>

            <VueCtkDateTimePicker
              locale="fr"
              v-model="date"
              style="display:inline-block;margin-bottom: 20px;"
              :range="true"
              color="#34495e"
              :no-label="true"
              :custom-shortcuts="customDateRangeShortcuts"
              :only-date="true"
              :class="{
                'is-invalid': submitted && $v.date.$error,
              }"
              :auto-close="false"
            ></VueCtkDateTimePicker>
            <div v-if="submitted && $v.date.$error" class="invalid-feedback">
              <span v-if="!$v.date.required">Champ obligatoire.</span>
              <br />
            </div>
          </div>
          <div class="col-md-4">
            <label for="formrow-inputCity">Site *</label>
            <multiselect
              :searchable="true"
              v-model="campId"
              track-by="id"
              label="name"
              :options="contract.selectedCampsWithServices"
              placeholder="Select Camp"
              :select-label="''"
              @input="onCampChange"
              :class="{
                'is-invalid': submitted && $v.campId.$error,
              }"
              :allow-empty="false">
              <template slot="singleCamp" slot-scope="{ camp }">{{
                camp.name
              }}</template>
            </multiselect>
            <div v-if="submitted && $v.campId.$error" class="invalid-feedback">
              <span v-if="!$v.campId.required">Champ obligatoire.</span>
              <br />
            </div>
          </div>

          <div class="col-md-3">
            <label for="formrow-inputCity">Prestation *</label>
            <multiselect
              :searchable="true"
              v-model="serviceId"
              track-by="id"
              label="name"
              :options="campServices"
              placeholder="Select Service"
              :select-label="''"
              :class="{
                'is-invalid': submitted && $v.serviceId.$error,
              }"
              :allow-empty="false">
              <template slot="signleService" slot-scope="{ service }">{{
                service.name
              }}</template>
            </multiselect>
            <div v-if="submitted && $v.serviceId.$error" class="invalid-feedback">
              <span v-if="!$v.serviceId.required">Champ obligatoire.</span>
              <br />
            </div>
          </div>
        </div>

        <div class="row">
         <div class="col-md-12">
            <button
            class="btn btn-primary float-right"
            @click.prevent="generateInvoice"
            type="submit"
          >
            <i class="fas fa-download"></i>
            Générer
          </button>
         </div>
        </div>
      </form>
    </Modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";

export default {
  props: {
    showModal: {},
    contract: null,
  },
  components: {Multiselect},

  validations: {
    date: {required},
    campId: {required},
    serviceId: {required},
  },

  data() {
    return {
      submitted: false,
      date: "",
      campId: "",
      serviceId: "",
      contractId: "",
      contracts: [],
      campServices: [],
      supplierName: "",
      contractRef: "",
      customDateRangeShortcuts: [
        { key: "thisWeek", label: "Cette semaine", value: "isoWeek" },
        { key: "lastWeek", label: "Semaine dernière", value: "-isoWeek" },
        { key: "last7Days", label: "7 derniers jours", value: 7 },
        { key: "last30Days", label: "30 derniers jours", value: 30 },
        { key: "thisMonth", label: "Ce mois", value: "month" },
        { key: "lastMonth", label: "Dernier mois", value: "-month" },
        { key: "thisYear", label: "Cette année", value: "year" },
        { key: "lastYear", label: "L'année dernière", value: "-year" },
      ],
    };
  },

  watch: {
    showModal() {
      if (this.showModal) {
        // this.fetchContractsList();
      }
    },

    contract(){
      if(this.contract){
        this.contractRef = this.contract.ref;
        this.supplierName = this.contract.supplier.prestname;
      }
    }
  },

  methods: {

    onCampChange(){
      this.serviceId = "";
      this.campServices = [];
      this.campServices = this.campId.services;
    },

    _checkDates() {
      return this.date.start != null && this.date.end != null;
    },
    
    generateInvoice() {
      this.submitted = true;
      this.$v.$touch();
      if (this._checkDates() && !this.$v.$invalid) {
        var loader = this.$loading.show();
        this.$http
          .post("/invoicing/generateInvoice", {
            contractId: this.contract.uuid,
            from: this.date.start,
            to: this.date.end,
            campId: this.campId.uuid,
            service: this.serviceId.id,
          })
          .then((res) => {
            loader.hide();
            var msg = res.data.msg;
            var status = res.data.status;
            var invoice = res.data.invoice;
            switch (status) {
                case 200:
                    var router = this.$router;
                    this.$toast.success(msg);
                    this.showModal = false;
                    // redirect to invoice display page
                    router.push({ name: "invoicing.display", params: {uid:invoice.uuid } });
                break;
            
                case 500:
                    this.$toast.warning(msg);
                break;
            }
          })
          .catch((error) => {
            loader.hide();
            this.$toast.warning(error.message);
            // error.response.status Check status code
          })
          .finally(() => {
            loader.hide();
            //Perform action in always
          });
      } else {
        this.$toast.error("Vous devez selectionner une Plage de dates");
      }
    },
    modalClosed() {
      this.$emit("closeNewInvoiceModal", true);
    },
  },
};
</script>
